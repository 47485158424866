<template>
    <CCard>
        <CCardHeader>
            Advancer Öğrenciler
        </CCardHeader>
        <CCardBody>
            <CInput label='Arama: ' placeholder='Aramak istediğiniz öğrenciyi yazınız...' :horizontal="{ label: 'w-auto', input: 'w-100'}" v-model='filterValue' />
            <CDataTable
                class='mb-0 table-outline'
                hover
                :items='tableItems'
                :fields='tableFields'
                head-color='light'
                :loading='isDataLoading'
                :items-per-page='itemsPerPage'
            >
                <template #no-items-view>
                    <CRow>
                        <CCol class='col-12 font-3xl text-center'>
                            <CIcon name='cil-x' />
                            Veri Bulunamadı
                        </CCol>
                    </CRow>
                </template>
                <td slot='id' slot-scope='{item}'>
                    <div>{{ item.id }}</div>
                    <div class='small text-muted'>
                    </div>
                </td>
                <td slot='avatarUrl_1X' class='text-center' slot-scope='{item}'>
                    <img
                        class='c-avatar-img'
                        style='width: 64px; height: 64px'
                        @error='fallback'
                        :src="item.avatar1X ? item.avatar1X : ' '" />
                </td>
                <td slot='name' slot-scope='{item}'>
                    <div class='clearfix'>
                    </div>
                    <div>{{ item.name }}</div>
                </td>
                <td
                    slot='registeredAt'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.registeredAt | formatDate }}</div>
                </td>
                <td
                    slot='eMail'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.eMail }}</div>
                </td>
                <td slot='manage' slot-scope='{item}'>
                    <router-link :to="'/ogrencileri-yonet/' + item.id" tag='button' role='button' block class='btn btn-success'>
                        <CIcon name='cil-pencil' />
                    </router-link>
                    <CButton @click='deleteStudentAlert(item.id)' color='danger' class='ml-1'>
                        <CIcon name='cil-x' />
                    </CButton>
                </td>
            </CDataTable>
            <CPagination
                v-if='totalPages !== 0'
                :pages='totalPages'
                :active-page='currentPage'
                :activePage.sync='currentPage'
                align='center'
                class='mt-3'
            />
        </CCardBody>
    </CCard>
</template>

<script>
import { StudentAdministration } from '@/services/api-service'

export default {
    name: 'Students',
    components: {},
    mounted() {
        this.loadStudents()
    },
    watch: {
        filterValue() {
            this.loadStudents()
        },
        currentPage() {
            this.loadStudents()
        },
    },
    data() {
        return {
            approvementState: '',
            filterValue: '',
            totalPages: 1,
            currentPage: 1,
            itemsPerPage: 50,
            isDataLoading: false,
            selected: 'Month',
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'avatarUrl_1X', label: '' },
                { key: 'name', label: 'Adı' },
                { key: 'registeredAt', label: 'Kayıt Tarihi', _classes: 'text-center' },
                { key: 'eMail', label: 'Mail', _classes: 'text-center' },
                { key: 'manage', label: 'Yönet' },
            ],
        }
    },
    methods: {
        fallback(event) {
            event.target.src = 'https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png'
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        async loadStudents() {
            this.isDataLoading = true
            const result = await StudentAdministration.listStudentsForManagement(this.filterValue !== '' ? this.filterValue : undefined, this.approvementState !== '' ? this.approvementState : undefined, this.currentPage)
            this.tableItems = result.data.students
            this.totalPages = result.data.studentsPagination.totalPages
            this.isDataLoading = false
        },
        deleteStudentAlert(id) {
            this.$swal({
                title: 'Öğrenciyi KVKK gereği Silmek istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.tableItems = this.tableItems.filter(r => r.id !== id)
                    await StudentAdministration.deleteStudentAccountDueGDPR(id, Date.now())
                    await this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
    },
}
</script>

